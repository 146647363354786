import React from "react"

export default ({ color, highlighted }) => {
  const isWhite = ["#FFFFFF", "white", "rgb(255, 255, 255)"].includes(color)
  const unhighlighted = isWhite ? "lightgrey" : color
  return (
    <div
      style={{
        borderRadius: "50%",
        display: "inlineBlock",
        height: "29px",
        width: "29px",
        marginRight: "10px",
        zIndex: "0",
        border: `2px solid ${highlighted ? "black" : unhighlighted}`,
        position: "relative",
        backgroundColor: color,
      }}
    ></div>
  )
}
